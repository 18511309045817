<template>
  <b-card title="المقالات">
    <b-tabs>
    <b-tab active>
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/add-article"
            class="no-color"
          >
          <feather-icon icon="PlusIcon" />
          <span>اضافة مقال </span>
          </a>
        </template>
<AddArticale></AddArticale>
      </b-tab>
     
      <b-tab @click="$router.push('/all-articales')" >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/all-articales"
            class="no-color"
          >
          <feather-icon icon="HomeIcon" />
          <span>كل المقالات </span>
          </a>
        </template>
        <router-view></router-view>
<!-- <AllArticales></AllArticales> -->
      </b-tab>
      <b-tab @click="$router.push('/all-news')" >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/all-news"
            class="no-color"
          >
          <feather-icon icon="HomeIcon" />
          <span>كل الاخبار </span>
          </a>
        </template>
        <router-view></router-view>
<!-- <AllArticales></AllArticales> -->
      </b-tab>
     
    </b-tabs>

  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'

import AllArticales from './AllArticales.vue';
import AddArticale from './AddArticale.vue';


export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BTabs,
    BTab,

    AllArticales,
    AddArticale
},
  data() {
    return {
   
    }
  },
}
</script>
